<template>
    <div>
        <avue-crud
            ref="crud" :option="tableOption" :data="list" :page="page" :table-loading="listLoading" 
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #selection='scope'>
                <el-radio-group size="large" @change="changeFun(scope.row)">
                    <el-radio-button :label="$t('button.checks')" />   
                </el-radio-group>
            </template>
        </avue-crud>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,defineEmits,defineExpose } from 'vue'
    import { tableOption1 } from "@/const/crud/cryptocurrency/customerWithdrawalArr";
    
    const crud = ref(null)
    const list = ref([])
    const listLoading = ref(false)
    const tableOption = ref({});
    tableOption.value = tableOption1(false)
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
    })
    const formValue = ref({})
    const emit = defineEmits(['getCoin1'])
    const changeFun = (e)=>{
        emit('getCoin1',e)
    }
    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize,
            ...formValue.value
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        listLoading.value = true
        Api_.getAssetswalletinfoList(obj_).then(res=>{
            if(res.code === 0){
                listLoading.value = false
                list.value = res.data.records
                page.total = res.data.total
            }
        })
    }
    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    const init_ = (e)=>{
        formValue.value = e
        getList(1)
    }
    defineExpose({init_})
    
</script>

<style lang="scss" scoped>
</style>