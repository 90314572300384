
export const tableOption1 = ()=> {
    return {
        border: true,
        menu: false,
        menuAlign: 'center',
        searchMenuSpan: 6,
        height: 450,
        align: 'center',
        dialogWidth: 400,
        searchSpan: 12,
        columnBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
    column: [{
        label: '链',
        prop: 'chain'
    },{
        label: '钱包名称',
        prop: 'walletName',
    },
    {
        label: '币种',
        prop: 'coin',
    },{
        label: '选着',
        prop: 'selection',
    },]
        
    }
}
export const collection = {
    border: true,
    menu: false,
    menuAlign: 'center',
    searchMenuSpan: 6,
    height: 500,
    align: 'center',
    dialogWidth: 400,
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    refreshBtn: false,
    column: [{
        width: 120,
        label: '选择',
        prop: 'selection',
    },{
        label: '商户',
        prop: 'tenantName',
    },{
        // width: 100,
        label: 'App名称',
        prop: 'appName',
    },{
        label: '系列名称',
        prop: 'collectionName'
    },{
        width:100,
        label: '作品名称',
        prop: 'itemName'
    },{
        width:100,
        label: '作品数量',
        prop: 'qty'
    }]
}